<template>
	<header class="app-header fixed-top">
		<div class="app-header-inner">
			<div class="container-fluid py-2">
				<div class="app-header-content">
					<div class="row justify-content-between align-items-center">

						<div class="col-auto">
							<a id="sidepanel-toggler" class="sidepanel-toggler d-inline-block d-xl-none" href="#"
								@click="sidePanelToggler()" v-if="getAuthStatus">
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"
									role="img">
									<title>Menu</title>
									<path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
										stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path>
								</svg>
							</a>
						</div>

						<div class="app-utilities col-auto">



							<div class="app-utility-item app-user-dropdown dropdown" v-if="getAuthStatus">
								<a class="dropdown-toggle" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#"
									role="button" aria-expanded="false"><img :src="require('@/assets/images/user.png')"
										alt="user profile"></a>
								<ul class="dropdown-menu" aria-labelledby="user-dropdown-toggle">
									<!--li><a class="dropdown-item" href="account.html">Account</a></li>
									<li><a class="dropdown-item" href="settings.html">Settings</a></li-->
									<li>
										<hr class="dropdown-divider">
									</li>
									<li><a class="dropdown-item" href="#" @click="logOut">Log Out</a></li>
								</ul>
							</div><!--//app-user-dropdown-->
						</div><!--//app-utilities-->
					</div><!--//row-->
				</div><!--//app-header-content-->
			</div><!--//container-fluid-->
		</div><!--//app-header-inner-->
		<div id="app-sidepanel" class="app-sidepanel" v-if="getAuthStatus">
			<div id="sidepanel-drop" class="sidepanel-drop" @click="sidePanelDrop()"></div>
			<div class="sidepanel-inner d-flex flex-column">
				<a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none" @click="sidePanelClose()">&times;</a>
				<div class="app-branding">
					<a class="app-logo" href="index.html"><img class="logo-icon me-2"
							:src="require('@/assets/images/app-logo.svg')" alt="logo"><span
							class="logo-text">eKonsulta</span></a>

				</div><!--//app-branding-->

				<nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
					<ul class="app-menu list-unstyled accordion" id="menu-accordion">
						<!--li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[0].active }"
								:to="routeLinks[0].links">
								<span class="nav-icon">
									<v-icon icon="mdi-home" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[0].text }}</span>
							</RouterLink>
						</li-->
						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[1].active }"
								:to="routeLinks[1].links">
								<span class="nav-icon">
									<v-icon icon="mdi-account-multiple-plus" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[1].text }}</span>
							</RouterLink>
						</li>
						
						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[2].active }"
								:to="routeLinks[2].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[2].text }}</span>
							</RouterLink>
						</li>

						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[3].active }"
								:to="routeLinks[3].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[3].text }}</span>
							</RouterLink>
						</li>

						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[4].active }"
								:to="routeLinks[4].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[4].text }}</span>
							</RouterLink>
						</li>

						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[5].active }"
								:to="routeLinks[5].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[5].text }}</span>
							</RouterLink>
						</li>

						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[6].active }"
								:to="routeLinks[6].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[6].text }}</span>
							</RouterLink>
						</li>

						<li class="nav-item">
							<RouterLink class="nav-link" :class="{ active: routeLinks[7].active }"
								:to="routeLinks[7].links">
								<span class="nav-icon">
									<v-icon icon="mdi-clipboard-list-outline" size="large"></v-icon>
								</span>
								<span class="nav-link-text">{{ routeLinks[7].text }}</span>
							</RouterLink>
						</li>

						<!--li class="nav-item has-submenu">
							
							<a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse"
								data-bs-target="#submenu-1" aria-expanded="false" aria-controls="submenu-1">
								<span class="nav-icon">
									
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-files"
										fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z" />
										<path
											d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z" />
									</svg>
								</span>
								<span class="nav-link-text">Pages</span>
								<span class="submenu-arrow">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down"
										fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</span>
							</a>
							<div id="submenu-1" class="collapse submenu submenu-1" data-bs-parent="#menu-accordion">
								<ul class="submenu-list list-unstyled">
									<li class="submenu-item"><a class="submenu-link"
											href="notifications.html">Notifications</a></li>
									<li class="submenu-item"><a class="submenu-link" href="account.html">Account</a>
									</li>
									<li class="submenu-item"><a class="submenu-link" href="settings.html">Settings</a>
									</li>
								</ul>
							</div>
						</li>
						<li class="nav-item has-submenu">
						
							<a class="nav-link submenu-toggle" href="#" data-bs-toggle="collapse"
								data-bs-target="#submenu-2" aria-expanded="false" aria-controls="submenu-2">
								<span class="nav-icon">
								
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-columns-gap"
										fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											d="M6 1H1v3h5V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm14 12h-5v3h5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5zM6 8H1v7h5V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H1zm14-6h-5v7h5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-5z" />
									</svg>
								</span>
								<span class="nav-link-text">External</span>
								<span class="submenu-arrow">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down"
										fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd"
											d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
									</svg>
								</span>
							</a>
							<div id="submenu-2" class="collapse submenu submenu-2" data-bs-parent="#menu-accordion">
								<ul class="submenu-list list-unstyled">
									<li class="submenu-item"><a class="submenu-link" href="login.html">Login</a></li>
									<li class="submenu-item"><a class="submenu-link" href="signup.html">Signup</a></li>
									<li class="submenu-item"><a class="submenu-link" href="reset-password.html">Reset
											password</a></li>
									<li class="submenu-item"><a class="submenu-link" href="404.html">404 page</a></li>
								</ul>
							</div>
						</li-->



					</ul><!--//app-menu-->
				</nav><!--//app-nav-->
				<!--div class="app-sidepanel-footer">
					<nav class="app-nav app-nav-footer">
						<ul class="app-menu footer-menu list-unstyled">
							<li class="nav-item">
						
								<a class="nav-link" href="settings.html">
									<span class="nav-icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear"
											fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd"
												d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
											<path fill-rule="evenodd"
												d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
										</svg>
									</span>
									<span class="nav-link-text">Settings</span>
								</a>
							</li>
							<li class="nav-item">
								
								<a class="nav-link"
									href="https://themes.3rdwavemedia.com/bootstrap-templates/admin-dashboard/portal-free-bootstrap-admin-dashboard-template-for-developers/">
									<span class="nav-icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download"
											fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd"
												d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
											<path fill-rule="evenodd"
												d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
										</svg>
									</span>
									<span class="nav-link-text">Download</span>
								</a>
							</li>
							<li class="nav-item">
								
								<a class="nav-link"
									href="https://themes.3rdwavemedia.com/bootstrap-templates/admin-dashboard/portal-free-bootstrap-admin-dashboard-template-for-developers/">
									<span class="nav-icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-file-person"
											fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd"
												d="M12 1H4a1 1 0 0 0-1 1v10.755S4 11 8 11s5 1.755 5 1.755V2a1 1 0 0 0-1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z" />
											<path fill-rule="evenodd" d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
										</svg>
									</span>
									<span class="nav-link-text">License</span>
								</a>
							</li>
						</ul>
					</nav>
				</div-->

			</div><!--//sidepanel-inner-->
		</div><!--//app-sidepanel-->
	</header><!--//app-header-->
</template>
  
<script>
import ApplicationBar from './ApplicationBar';

export default ApplicationBar;
</script>
