/* eslint-disable */
import { RouterLink } from "vue-router";
import { mapActions, mapGetters } from "vuex";
export default {
    name: 'ApplicationBar',
    data: () => ({
        screenwidth: 0,
        routeLinks: [
            { text: 'Dashboard', links: '/', name: 'dashboard', active: false },
            { text: 'Registration', links: '/memberregistration', name: 'memberregistration', active: false },
			{ text: 'Profilling', links: '/HealthScreeningAndAssessment', name: 'HealthScreeningAndAssessment', active: false },
			{ text: 'Consultation', links: '/Consultation', name: 'Consultation', active: false },
			{ text: 'EPRESS', links: '/Eprescription', name: 'Eprescription', active: false },
			{ text: 'EKAS', links: '/Ekonsavslip', name: 'Ekonsavslip', active: false },
            { text: 'Doctors List', links: '/Doctorslist', name: 'Doctorslist', active: false },
            { text: 'Data Privacy', links: '/DataPrivacy', name: 'DataPrivacy', active: false },
        ]
    }),
    computed: {
        ...mapGetters(["getAuthStatus", "getCurrentUser"]),
    },
    mounted() {
        this.checkAuth()
            .then((resp) => {
            window.addEventListener("resize", this.responsiveSidePanel);
        })
            .catch((err) => {
        });
        this.navigateLinks();
    },
    unmounted() {
        window.addEventListener("resize", this.responsiveSidePanel);
    },
    methods: {
        ...mapActions(["checkAuth", "signOut"]),
        async routeName() {
            return new Promise((resolve) => {
                resolve(this.$route.name);
            });
        },
        async navigateLinks() {
            let currentRouteName = await this.routeName();
            for (let i = 0; i < this.routeLinks.length; i++) {
                this.routeLinks[i].active = false;
				
                if (this.routeLinks[i].name == currentRouteName) {
					
                    this.routeLinks[i].active = true;
                }
            }
        },
		setNavigationActive(linkName){
			for (let i = 0; i < this.routeLinks.length; i++) {
                this.routeLinks[i].active = false;
                if (this.routeLinks[i].name == linkName) {
                    this.routeLinks[i].active = true;
                }
            }
		},
        responsiveSidePanel(e) {
            if (this.getAuthStatus) {
                const sidePanel = document.getElementById('app-sidepanel');
                this.screenwidth = window.innerWidth;
                if (this.screenwidth >= 1200) {
                    // if larger 
                    //console.log('larger');
                    sidePanel.classList.remove('sidepanel-hidden');
                    sidePanel.classList.add('sidepanel-visible');
                }
                else {
                    // if smaller
                    //console.log('smaller');
                    sidePanel.classList.remove('sidepanel-visible');
                    sidePanel.classList.add('sidepanel-hidden');
                }
            }
        },
        sidePanelToggler() {
            if (this.getAuthStatus) {
                const sidePanel = document.getElementById('app-sidepanel');
                if (sidePanel.classList.contains('sidepanel-visible')) {
                    
                    sidePanel.classList.remove('sidepanel-visible');
                    sidePanel.classList.add('sidepanel-hidden');
                }
                else {
                    
                    sidePanel.classList.remove('sidepanel-hidden');
                    sidePanel.classList.add('sidepanel-visible');
                }
            }
        },
        sidePanelClose() {
            this.sidePanelToggler();
        },
        sidePanelDrop() {
            this.sidePanelToggler();
        },
        logOut(e) {
			e.preventDefault()
            this.signOut()
            .then((resp) => {
			   this.$router.push({ name: 'home' })
            })
            .catch((err) => {
				this.$router.push({ name: 'home' })
            });
        }
    },
    components: { RouterLink }
}