/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'


const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      auth: 0
    },
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      auth: 0
    },
    component: () => import('../views/RegistrationView.vue')
  },

  {
    path: '/HealthScreeningAndAssessment',
    name: 'HealthScreeningAndAssessment',
    meta: {
      auth: 1
    },
    component: () => import('../views/HealthScreeningAndAssessmentView.vue')
  },
  {
    path: '/AddEditHealthScreeningAndAssessment',
    name: 'AddEditHealthScreeningAndAssessment',
    meta: {
      auth: 1
    },
    component: () => import('../components/HealthScreeningAndAssessment/AddEditHealthScreeningAndAssessment.vue')
  },
  
  {
    path: '/memberregistration',
    name: 'memberregistration',
    meta: {
      auth: 1
    },
    component: () => import('../views/MemberRegistrationView.vue')
  },
  {
    path: '/AddEditMemberRegistration',
    name: 'AddEditMemberRegistration',
    meta: {
      auth: 1
    },
    component: () => import('../components/MemberRegistration/AddEditMemberRegistration.vue')
  },
  
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      auth: 1
    },
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Consultation',
    name: 'Consultation',
    meta: {
      auth: 1
    },
    component: () => import('../views/ConsultationView.vue')
  },
  {
    path: '/ConsultationEntry',
    name: 'ConsultationEntry',
    meta: {
      auth: 1
    },
    component: () => import('../components/ConsultationEntry/ConsultationEntryView.vue')
  },
  {
    path: '/Eprescription',
    name: 'Eprescription',
    meta: {
      auth: 1
    },
    component: () => import('../views/EpresView.vue')
  },
  {
    path: '/Ekonsavslip',
    name: 'Ekonsavslip',
    meta: {
      auth: 1
    },
    component: () => import('../views/EkasView.vue')
  },
  {
    path: '/Doctorslist',
    name: 'Doctorslist',
    meta: {
      auth: 1
    },
    component: () => import('../views/DoctorsListView.vue')
  },
  {
    path: '/DataPrivacy',
    name: 'DataPrivacy',
    meta: {
      auth: 1
    },
    component: () => import('../views/DataPrivacyView.vue')
  },
  
  {
    path: '/about',
    name: 'about',
    meta: {
      auth: 1
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


let checkAuth = (to) => {
  return new Promise((resolve, reject) => {
    
    axios({
      method: "post",
      url: process.env.VUE_APP_API + "auth/me",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ua")
      },
    })
      .then((resp) => {
        resolve(resp)
      })
      .catch((err) => {
        
        reject(err)
      })
  })
}
 
router.beforeEach((to, from, next) => {
 
  if (localStorage.getItem('ua')) {
    
    checkAuth(to)
        .then((resp) => {
          if(to.meta.auth == 1){
            next()
          }else{
            next({ name: 'dashboard' });
          }
        }).catch((err) => {
          
          localStorage.removeItem('ua')
          if(to.name != 'home'){
            next({ name: 'home' });
          }
        })
  }else{
   
    if(to.meta.auth == 0){
      next()
    }else{
      next({ name: 'home' });
    }
  
  }
})

export default router
